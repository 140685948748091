.mainmedia {
  background-color: #f7f7f7;
  padding-bottom: 10%;

}
.blog img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .blog .items {
    padding: 20px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    width: 100%;
    margin-left: -30%;
  }
  .headingMedia {
    margin-left: 7%;
    padding: 50px 0 0px 0;
    margin-top: -35%;
  }
  .category span {
    font-size: 14px;
    color: grey;
    transition: 0.5s ease-in-out;
  }
  .category label {
    background-color: #31b675;
    font-size: 13px;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
  }
  .gridMedia {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
  }
  .blog .items h3 {
    margin: 5px 0 10px 0;
    font-weight: 500;
    transition: 0.5s ease-in-out;
  }
  .blog .items p {
    font-size: 14px;
    color: grey;
    margin-bottom: 10px;
    transition: 0.5s ease-in-out;
  }
  .blog .items a {
    color: black;
    font-weight: 500;
    font-size: 14px;
    transition: 0.5s ease-in-out;
  }
  .blog .items a i {
    color: #31b675;
    transition: 0.5s ease-in-out;
  }
  .webdata {
    display: block;
    /* margin-left: 20%; */
    padding: 0;
  }
  @media screen and (min-width:1025px) and (max-width: 1280px) {
    .blog img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      margin-bottom: 20px;
      margin-top: -8%;
    }
  }
 @media screen and (min-width:993px) and (max-width: 1024px) {
  .blog img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: -10%;
  }
}
 @media screen and (min-width:769px) and (max-width: 992px) {
  
    .gridMedia {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 100px;
    }
    .webdata {
      display: block;
      margin-left: -10%;
    }
  }
  @media screen and (min-width:577px) and (max-width: 768px) {
    .webdata {
      /* display: none; */
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-left: -15%;
    }
    .gridMedia {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 12px;
    }
    .headingMedia {
      margin-left: 7%;
      margin-top: -50%;
    }
    .blog .items {
      padding: 20px;
      border-radius: 5px;
      transition: 0.5s ease-in-out;
      margin-left: 0%;
      width: 100%;
    }
  }
  @media screen and (min-width:270px) and (max-width: 576px) {
   
    .webdata {
      /* display: none; */
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-left: -15%;
    }
    .gridMedia {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0px;
      /* margin-left: -6%; */

    }
    .headingMedia {
      margin-left: 7%;
      margin-top: -50%;
    }
    .blog .items {
      padding: 20px;
      border-radius: 5px;
      transition: 0.5s ease-in-out;
      width: 100%;
      margin-left: 0%;
    }
  }
   /* @media screen and (max-width: 820px) {
  
    .gridMedia {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 100px;
    }
  }
   */