/* @import url('./Montserrat_fonts/Montserrat-Italic-VariableFont_wght.ttf'); */
@font-face {
  font-family: "Montserrat";
  src: local('Montserrat'), url('./Montserrat_fonts/static/Montserrat-Bold.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

body {
  /* font-family: "Poppins", Montserrat; */
  font-family: "Montserrat";

}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex_space {
  display: flex;
  justify-content: space-between;
}

/* .container {
  max-width: 100%;
  margin: auto;
} */
li {
  list-style-type: none;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.primary-btn {
  padding: 10px 20px;
  background-color: #000;
  color: white;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 25px;
}

.primary-btn:hover {
  background-color: #000;
  color: white;
}

.secondary-btn {
  padding: 12px 30px;
  background-color: #31b675;
  color: white;
  font-weight: 400;
  font-size: 15px;
  margin-top: 20px;
}

.outline-btn {
  padding: 12px 30px;
  background-color: #fff;
  color: black;
  font-weight: 400;
  font-size: 15px;
  margin-top: 20px;
  border: 2px solid #000;
}

.secondary-btn i {
  margin: 7px 0 0 10px;
}

.control-btn button {
  background-color: rgba(255, 255, 255, 0.3);
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.control-btn i {
  background-color: #31b675;
  width: 45px;
  height: 45px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 30px;
}

input {
  width: 100%;
  padding: 20px;
  outline: none;
  /* border: none; */
  margin: 10px;
  border-radius: 35px;
}

.mediabgimage {
  /* width: 50%;
  margin-left: 50%; */
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
}

.row-about {
  width: 50%;
}

.top {
  margin: 100px 0;
}

.mtop {
  margin-top: 50px;
}

.allmediatop {
  margin-top: 50px;
}

.heading {
  text-align: center;
  padding: 50px 0 50px 0;
}

.heading h1 {
  font-size: 35px;
  font-weight: 500;
}

.heading .line {
  width: 18%;
  margin: auto;
  margin-top: 20px;
  border-bottom: 2px dashed #999999;
  position: relative;
}

.heading .line::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #31b675;
  border-radius: 50%;
  top: -6px;
  left: -20px;
}

.heading .line::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #31b675;
  border-radius: 50%;
  top: -6px;
  right: -20px;
}

.full_container {
  max-width: 95%;
  margin: auto;
}

.main-content {
  width: 55%;
}



.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 85px;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.ticker {
  margin: 15px 0;
  padding-left: 5px;
}

.ticker li {
  margin: 5px 0;
}

.icon {
  margin-top: 20px;
}

.icon i {
  background: lightgray;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
}

.slick-prev:before{
  color: #000 !important;
}
.slick-next:before {
  color: #000 !important;
}
.slider-container{
  padding: 40px;
}
.slide_imge {
  width: 115px;
  height: 165px;
  margin-bottom: 15px;
}


/* .slide{
  float: left;
    list-style: none;
    position: relative;
    margin-right: 30px;
    width: 160px;
} */



/* 
@media screen and (max-width: 820px) {
  .container {
    max-width: 90%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
 
} */



/* Loader.css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  /* animation: spin 1s linear infinite; */
  animation: spin 3s linear infinite;
}
.fa-external-link-alt:before {
    content: "\f35d";
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@media screen and (min-width: 769px) and (max-width: 992px) {

  /* .container {
    max-width: 90%;
  } */
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

  }

  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }

  .main-content {
    width: 100%;
  }

  .side-content {
    width: 100%;
    margin-left: 0px;
  }

}

@media screen and (min-width: 577px) and (max-width: 768px) {

  /* .container {
    max-width: 90%;
  } */
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

  }

  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }

  .main-content {
    width: 100%;
  }

  .side-content {
    width: 100%;
    margin-left: 0px;
  }

}

@media screen and (min-width: 280px) and (max-width: 576px) {
  .allmediatop {
    margin-top: 18px;
  }

  /* .container {
    max-width: 90%;
  } */
  .grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  .carousel-indicators {
    margin-bottom: 0px;
  }

  /* .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  } */
  .main-content {
    width: 100%;
  }

  .side-content {
    width: 100%;
    margin-left: 0px;
  }

  .gallery .items {
    padding: 0 !important;
    margin-bottom: 15px;
  }

  .primary-btn {
    padding: 10px 10px;
    background-color: #000;
    color: white;
    font-weight: 400;
    transition: 0.5s;
    border-radius: 25px;
  }


}

@media screen and (min-width: 270px) and (max-width: 576px){
  .slider-img {
    max-width: 100%;
}
}



/*
@media screen and (max-width: 468px) {
  .container {
    max-width: 90%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
 
} */