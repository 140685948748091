.forms {
  background-color: lightgray;
  background-image: url('../../images/career.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.sign-box {
  width: 50%;
  margin: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 40px;
}

.forms form div {
  margin: 10px 0;
}

.forms form .flex input {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.forms p {
  font-size: 13px;
}

.forms form label {
  margin: 8px;
}

.forms form span {
  margin-top: 10px;
  color: crimson;
  font-size: 13px;
}

.forms form button {
  width: 30%;
  border-radius: 35px;
}

.forms form a {
  color: blue;
  font-size: 13px;
}

.button1 {
  width: 30%;
  margin-right: 70%;
  margin-bottom: 2%;
}

.inputrow {
  width: 50%;
  padding: 20px;
  outline: none;
  border: none;
  margin-left: 315px;
  border-radius: 35px;
  text-align: center;

}

.message {
  width: 100%;
  padding: 20px;
  outline: auto;
  border: none;
  border-radius: 35px;
  margin-left: 2%;
}

.careerimage {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 180px;
}

.forms .container {
  position: relative;
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .sign-box {
    width: 50%;
    margin: auto;
  }

  .inputrow {
    width: 50%;
    padding: 20px;
    outline: none;
    border: none;
    margin-left: 305px;
    border-radius: 35px;
    text-align: center;

  }

  .careerimage {
    width: 25%;
    margin-top: -25%;
    margin-left: 2%;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .sign-box {
    width: 50%;
    margin: auto;
  }

  .inputrow {
    width: 50%;
    padding: 20px;
    outline: none;
    border: none;
    margin-left: 210px;
    border-radius: 35px;
    text-align: center;

  }

  .careerimage {
    width: 20%;
    margin-top: -25%;
    margin-left: 0%;
  }

  .button1 {
    width: 30%;
    margin-right: 65%;
    margin-bottom: 2%;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .sign-box {
    width: 50%;
    margin: auto;
  }

  .inputrow {
    width: 75%;
    padding: 20px;
    outline: none;
    border: none;
    margin-left: 99px;
    border-radius: 35px;
    text-align: center;
  }

  .careerimage {
    width: 20%;
    margin-top: -25%;
    margin-left: -5%;
  }

  .button1 {
    width: 30%;
    margin-right: 65%;
    margin-bottom: 2%;
  }
}

@media screen and (min-width:577px) and (max-width: 768px) {
  .sign-box {
    width: 50%;
    margin: auto;
  }

  .inputrow {
    width: 75%;
    padding: 20px;
    outline: none;
    border: none;
    margin-left: 85px;
    border-radius: 35px;
    text-align: center;

  }

  .button1 {
    width: 30%;
    margin-right: 65%;
    margin-bottom: 2%;
  }

  .careerimage {
    width: 20%;
    margin-top: -25%;
    margin-left: -5%;
  }

}

@media screen and (min-width:270px) and (max-width: 576px) {
  .sign-box {
    width: 50%;
    margin: auto;
  }

  .inputrow {
    width: 100%;
    padding: 20px;
    outline: none;
    border: none;
    margin-left: 0%;
    border-radius: 35px;
    text-align: center;
  }

  .careerimage {
    display: none;
  }

  .button1 {
    width: 40%;
    margin-right: 50%;
    margin-bottom: 2%;
  }
}

/* @media screen and (max-width: 820px) {
  .sign-box {
    width: 50%;
    margin: auto;
  }
  .inputrow {
    width: 50%;
    padding: 20px;
    outline: none;
    border: none;
    margin-left: 173px;
    border-radius: 35px;
    text-align: center;
    
  }
  
} */