.mainproduct {
  background-color: #f7f7f7;
}

.gallery {
  margin-bottom: 50px;
  padding-bottom: 5%;

}

.gallery img {
  transition: 0.5s;
  width: 100%;
  height: 100%;
}

.gallery .items {
  padding: 15px;
  border-radius: 5px;
  /* margin-left: 10%; */
  /* text-align: center; */
}

.gallery h3 {
  margin: 10px 0 10px 0;
  font-weight: 400;
}

.gallery .img {
  position: relative;
  overflow: hidden;
  /* width: 530px;
  height: 480px; */
}

.gallery i {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 5;
  font-size: 40px;
  color: white;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.gallery .img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.gallery .img::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  z-index: 3;
  border: 1px solid #fff;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.gallery .items:hover img {
  transform: scale(1.2);
  cursor: pointer;
}

.gallery .items:hover i,
.gallery .items:hover .img::after,
.gallery .items:hover .img::before {
  opacity: 1;
  cursor: pointer;
}

.popup {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 8;
}

.hide {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup-content {
  position: absolute;
  top: 15%;
  left: 20%;
}

.popup button {
  background-color: #fff;
  padding: 20px 20px;
  color: #000;
  position: absolute;
  top: -7%;
  right: -34.5%;
  font-weight: bold;
}

.producttitle {
  margin-left: 10%;
}

.desi .ptitle {
  margin: 0;
  background-color: #f7f7f7;
}

.desi .desc {
  margin: 0;
  background-color: #f7f7f7;
}

.desi h3 {
  margin: 0;
  padding: 15px 0 0 0;
  color: black;
}



/*  @media screen and (max-width: 820px) {
    .gallery .container {
      max-width: 100%;
    }
    .gallery .img {
      width: 100%;
      height: 200px;
    }
  
    .gallery .img::after {
      width: 300px;
      height: 180px;
    }
    .popup-content {
      padding: 50px;
      position: absolute;
      top: 30%;
      left: 0%;
    }
    .popup button {
      background-color: #fff;
      padding: 20px 20px;
      color: #000;
      position: absolute;
      top: -2%;
      right: 6.5%;
      font-weight: bold;
    }
  }
   */

/*
 
  @media screen and (max-width: 468px) {
    .gallery .container {
      max-width: 100%;
    }
    .gallery .img {
      width: 100%;
      height: 170px;
    }
    .gallery .items {
      padding: 0px;
      border-radius: 5px;
    }
    .gallery .img::after {
      width: 300px;
      height: 180px;
    }
    .popup-content {
      padding: 50px;
      position: absolute;
      top: 30%;
      left: 0%;
    }
    .popup button {
      background-color: #fff;
      padding: 20px 20px;
      color: #000;
      position: absolute;
      top: -2%;
      right: 6.5%;
      font-weight: bold;
    }
  } */




  /* .btn {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 20px; 
    background-color: #ccc; 
    margin-right: 10px;
  }
  
  .btn.active {
    background-color: #ff0000; 
  } */

  .categories {
    display: flex;
    flex-wrap: wrap; /* This allows buttons to wrap onto the next line if needed */
    gap: 10px; /* Spacing between buttons */
    margin-bottom: 20px; /* Add some space below the buttons */
  }
  
  .btn {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .btn:hover {
    background-color: #0056b3;
    color: white;
  }
  

  .img {
    /* width: 200px;
    height: 200px;  */
   
    overflow: hidden; /* Ensures images are clipped if they exceed the container */
  }
  
  .img img {
    width: 100%; /* Image will take full width of the container */
    height: 100%; /* Image will take full height of the container */
    object-fit: cover; /* Ensures the image covers the entire area without stretching */
    display: block; /* Removes any small gaps below the image */
  }
  
  