
.aboutimgbrand {
    width: 14%;
    margin-right: 100px;
}

.brandimage {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (min-width:1025px) and (max-width: 1280px) {
    .aboutbrand {
        margin-left: -3%;
    }
}

@media screen and (min-width:993px) and (max-width: 1024px) {
    .aboutbrand {
        margin-left: -7%;
    }

    .aboutimgbrand {
        width: 14%;
        margin-right: 107px;
    }
}

@media screen and (min-width:769px) and (max-width: 992px) {
    .aboutbrand {
        margin-left: -8%;
    }

    .aboutimgbrand {
        width: 10%;
        margin-right: 100px;
    }
}

@media screen and (min-width:577px) and (max-width: 768px) {
    .aboutbrand {
        margin-left: -15%;
    }

    .aboutimgbrand {
        width: 10%;
        margin-right: 100px;
    }
}

@media screen and (min-width:270px) and (max-width: 576px) {
    .aboutbrand {
        margin-left: -25%;
    }

    .aboutimgbrand {
        width: 10%;
        margin-right: 50px;
    }
}