.slider-container {
    margin: 0 auto;
    /* padding: 20px; */
    width: 100%;
    /* max-width: 1200px; */
  }
  
  .product-wrapper {
    padding: 10px;
  }
  
  .product-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* box-shadow: 3px 3px 3px #747474; */
    padding: 10px;
  }
  
  .product-image {
    max-width: 150px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .product-info {
    text-align: center;
  }
  
  .product-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .blockquote-footer {
    font-size: 12px;
    color: gray;
  }
  