/* Home.css */
.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    margin: 10px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
  }
  
  .product-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .product-details {
    padding: 15px;
  }
  
  .product-details h3 {
    font-size: 1.2em;
    margin: 10px 0;
    color: #333;
  }
  
  .product-details p {
    font-size: 0.9em;
    color: #666;
    margin: 5px 0;
  }
  
  .product-price {
    font-size: 1.1em;
    color: #e91e63;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .product-author {
    font-size: 0.85em;
    color: #555;
    margin-top: 5px;
  }
  
  @media screen and (max-width: 768px) {
    .product-list {
      justify-content: center;
    }
  
    .product-card {
      width: 90%;
    }
  }
  