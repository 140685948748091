/* .aboutBodyTitle h1 {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 200px;
    cursor: pointer;
} */

.aboutBodyTitle {
    position: relative;
}

.aboutbgimg2 {
    width: 50%;
    margin-top: -20%;
    position: absolute;
    top: 0;
    left: 0;
}

.about-body {
    margin-top: -20%;
}

.aboutimgbody {
    width: 100%;
    /* margin-left: 100px; */
}

.aboutimg-rowbody {
    width: 40%;
    margin-top: -30%;
    margin-left: 50%;

}

.about-rowpara {
    width: 40%;
    padding-left: 12%;
    text-align: justify;

}

@media screen and (min-width:1025px) and (max-width: 1280px) {
    .about-body {
        margin-left: -3%;
    }

    .aboutimg-rowbody {
        width: 45%;
        margin-top: -35%;
        margin-left: 50%;

    }
}

@media screen and (min-width:993px) and (max-width: 1024px) {
    .about-body {
        margin-left: -15%;
    }

    .aboutBodyTitle h1 {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 280px;
        cursor: pointer;
    }

    .aboutimg-rowbody {
        width: 50%;
        /* padding-bottom: 30%;  */

    }

    .aboutimgbody {
        width: 100%;
        margin-left: -75%;
        margin-top: -65%;
        padding-bottom: 50%;
    }

    .about-rowpara {
        width: 90%;
        padding-left: 12%;
        text-align: justify;
        margin-top: 40%;
    }
}

@media screen and (min-width:769px) and (max-width: 992px) {
    .about-body {
        margin-left: -8%;
    }

    .aboutimg-rowbody {
        width: 50%;
        /* padding-bottom: 30%;  */

    }

    .aboutimgbody {
        width: 100%;
        margin-left: -75%;
        margin-top: -70%;
        padding-bottom: 50%;
    }

    .about-rowpara {
        width: 90%;
        padding-left: 12%;
        text-align: justify;
        margin-top: 40%;
    }
}

@media screen and (min-width:577px) and (max-width: 768px) {
    .about-body {
        margin-left: -15%;
    }

    .aboutimg-rowbody {
        width: 50%;
        /* padding-bottom: 30%;  */

    }

    .aboutimgbody {
        width: 100%;
        margin-left: -75%;
        margin-top: -95%;
        padding-bottom: 65%;
    }

    .about-rowpara {
        width: 90%;
        padding-left: 12%;
        text-align: justify;
        margin-top: 40%;
    }
}

@media screen and (min-width:270px) and (max-width: 576px) {
    .about-body {
        margin-left: -38%;
        margin-top: -35%;
    }

    .aboutimgbody {
        width: 100%;
        margin-top: -20px;
    }

    .aboutimg-rowbody {
        display: none;

    }

    .about-rowpara {
        width: 90%;
        padding-left: 12%;
        text-align: justify;

    }
}

/* @media screen and (max-width: 820px) {
    .about-body{
        margin-left: -15%;
    }
}


 */
/* @media screen and (max-width: 468px) {
    .about-body {
        margin-left: -35%;
    }
} */