/* footer {
    background-color: lightgray;
    color: white;
  
  }
  
  footer .icon i {
    color: black;
    margin: auto;
  } */
  
  .legal {
    text-align: center;
    padding-top: 1px;
    padding-bottom: 18px;
    background-color: lightgray;
    color: black;
    margin-top: -4%;
  }
  /* .legal p {
    opacity: 0.5;
  } */
   /* Footer.css */

footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

/* .legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.legal a {
  color: #333;
  text-decoration: none;
  margin-right: 15px;
}

.social-icon a {
  margin-left: 10px;
  color: #333;
  text-decoration: none;
}

.social-icon a:hover {
  color: #007bff;
}

  @media screen and (min-width:993px) and (max-width: 1024px) {
    footer {
      background-color: lightgray;
      color: white;
      /* margin-top: 32px; */
    }
  }
  @media screen and (min-width:769px) and (max-width: 992px) {
    footer {
      background-color: lightgray;
      color: white;
      /* margin-top: 32px; */
    }
  }
  @media screen and (min-width:577px) and (max-width: 768px) {
    footer .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    footer {
      background-color: lightgray;
      color: white;
      /* margin-top: 50px; */
    }
    footer .icon i {
      color: black;
      margin: auto;
    }
    .legal {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 18px;
      background-color: lightgray;
      color: black;
      /* margin-top: -7%; */
    }
    .social-icon {
      text-align: center;
    }
  }
  @media screen and (min-width:270px) and (max-width: 576px) {
    footer .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    footer {
      background-color: lightgray;
      color: white;
      /* margin-top: 40px; */
    }
    footer .icon i {
      color: black;
      margin: auto;
    }
    .legal {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 18px;
      background-color: lightgray;
      color: black;
      /* margin-top: -14%; */
    }
    .social-icon {
      text-align: center;
    }
  }
  /* @media screen and (max-width: 820px) {
    footer {
      background-color: lightgray;
      color: white;
      margin-top: 32px;
    }
  }*/
 
  