.back {
    position: absolute;
    right: 8%;
    top: -70px;
  }
  .single-page {
    position: relative;
  }
  
  .main-content p {
    margin: 10px 0 20px 0;
    color: grey;
    font-weight: bold;
  }
  .main-content .para p {
    margin-right: 10px;
    font-weight: bold;
  }
  .main-content h1 {
    font-size: 30px;
    font-family: Montserrat;
    font-weight: bold;
  }
  .main-content img {
    width: 60%;
    height: 60%;
    margin-left: 35%;
  }
  
  article .box {
    background-color: #31b675;
    padding: 30px;
    color: white;
  }
  article .box h2 {
    font-size: 30px;
    font-weight: 500;
  }
  article .box2 {
    margin-top: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    width: 100%;
    position: relative;
    padding: 20px;
  }
  article .box2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: 180px;
    width: 100%;
  }
  article p {
    font-size: 14px;
    line-height: 25px;
  }
  article .box2 p {
    text-align: center;
    position: relative;
    z-index: 2;
    font-size: 14px;
    line-height: 25px;
    color: white;
  }
  .container_sub {
    max-width: 1200px;
    margin: 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  /* .left {
    display: flex;
    height: 50vh;
  }
  .left_1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  } */
  /* .img_wrap {
    width: 90px;
    height: 80px;
    border: 1px solid #eee;
    cursor: pointer;
  }
  .img_wrap img {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
  .left_2 {
    width: 50%;
    height: 52%;
  }
  .left_2 img {
    width: 500px;
    height: 200%;
    object-fit: contain;
    margin-top: -74%;
  } */
  .active {
    border: 2px #e77600;
  }

  .ticker {
    list-style: none;
  }
  
  .ticker li:before {
    content: '✓ ';
  }

  .cart-btn{
    border: 1px solid;
    color: red;
    border-radius: 19px;
    width: 32%;
  }




  
  .single-page .side-content h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .single-page .side-content p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .single-page .side-content p strong {
    font-weight: 600; /* Slightly bolder but not as bold as the product name */
  }
  
  .single-page .side-content .description {
    font-size: 1.2rem;
    font-weight: 400; /* Lighter font for the description */
    margin-top: 1rem;
  }
  
  .cart-btn {
    /* background-color: #28a745;
    color: white; */
    padding: 0.7rem 1.5rem;
    /* border: none; */
    font-size: 1rem;
    cursor: pointer;
  }
  
  /* .cart-btn:hover {
    background-color: #218838;
  } */
  










  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .left_2 img {
      width: 500px;
      height: 85%;
      object-fit: contain;
      margin-top: -90%;
  }
} 
  @media screen and (min-width: 993px) and (max-width: 1024px) {
    .single-page .flex {
      flex-direction: column;
    }
    .left_2 img {
      width: 500px;
      height: 85%;
      object-fit: contain;
      margin-top: -110%;
      margin-left: 60%;
      }
      .side-content {
        width: 92%;
        margin-left: 45px;
        font-weight: bold;
        font-family: Montserrat;
        margin-top: 10%;
    }
    .cart-btn{
      border: 1px solid;
      color: red;
      border-radius: 19px;
      width: 100%;
    }
  }
  @media screen and (min-width:769px) and (max-width: 992px) {
    .cart-btn{
      border: 1px solid;
      color: red;
      border-radius: 19px;
      width: 100%;
    }
    .single-page .flex {
      flex-direction: column;
    }
    .left_2 img {
      width: 370px;
      height: 100%;
      object-fit: contain;
      margin-top: -85%;
      margin-left: 45%;
    }
  }
  @media screen and (min-width:577px) and (max-width: 768px) {
    .cart-btn{
      border: 1px solid;
      color: red;
      border-radius: 19px;
      width: 100%;
    }
    .single-page .flex {
      flex-direction: column;
    }
    .left_2 img {
      width: 296px;
      height: 85%;
      object-fit: contain;
      margin-top: -100%;
      margin-left: 45%;

  }
  }
  @media screen and (min-width:270px) and (max-width: 576px) {
    .cart-btn{
      border: 1px solid;
      color: red;
      border-radius: 19px;
      width: 100%;
    }
    .left_1 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: -15%;
      margin-top: -15%;
  }
    .single-page .flex {
      flex-direction: column;
    }
    .left_2 img {
      width: 250px;
      height: 85%;
      object-fit: contain;
      margin-top: -180%;
      margin-left: 45%;

  }
  }
 /* @media screen and (max-width: 820px) {
    .single-page .flex {
      flex-direction: column;
    }
  }
  
  */