.aboutTitle h1 {
    margin-top: 100px;
    margin-left: 200px;
    cursor: pointer;
}

div {
    background-color: rgba(48, 41, 41, 0);
}

.mainabout {
    background-color: #f7f7f7;
    position: relative;
}